<template>
  <b-container
    :class="['overlay-bg', 'wall']"
    :style="containerStyle"
    id="Smooth-header"
    fluid
  >
    <VueFlux :style="vuefluxStyle" />

    <b-container class="container__scene slideCard" id="id-card" fluid>
      <!--  -->
      <div :class="['container__perspective', !card_flipped || 'is-flipped']">
        <!-- FRONT -->
        <div class="container__card front box__shadow">
          <aside>
            <b-badge
              href="#"
              variant="light"
              class="px-1"
              @click="handleReverseCard"
            >
              <b-icon :icon="card_flipped ? 'front' : 'back'" />
            </b-badge>
            <b-badge
              href="#"
              :variant="isRunningDragRandom ? 'success' : 'danger'"
              class="px-1"
              @click="
                isRunningDragRandom ? dragRandomStart() : dragRandomStop()
              "
            >
              <b-icon :icon="isRunningDragRandom ? 'play' : 'stop'" />
            </b-badge>
            <b-badge
              variant="light"
              class="px-1"
              v-on:mouseover="setDrag"
              v-on:mouseleave="removeDrag"
            >
              <b-icon icon="arrows-move" />
            </b-badge>
            <b-badge
              href="#"
              variant="danger"
              class="px-1"
              @click="no_implemented"
            >
              <b-icon icon="x-square-fill" />
            </b-badge>
          </aside>
          <article>
            <b-row class="mt-4">
              <b-col cols="4" class="__separator">
                <QrCode
                  :value="commons.qrcode.cv"
                  color="white"
                  background="transparent"
                  className="transition text-center"
                  :size="120"
                  v-if="show_qr_code"
                />
                <div
                  class="identity_picture mx-auto transition"
                  v-else
                  @mouseover="handleQrCode()"
                />

                <div class="text-center font_bungee user-select-none">
                  <span v-if="show_qr_code" class="text__shadow transition"
                    >Scan {{ qrcodeTimer }} sec</span
                  >
                  <WordCarouselFade
                    tag="span"
                    :words="view_home.wideAsideCarousel"
                    class="text__shadow"
                    v-else
                  />
                </div>

                <div class="text-center fontStandard mt-2">
                  <h6>
                    <b-badge
                      href="mailto:contact@julienjean.ovh"
                      variant="warning"
                      class="box__shadow"
                      >Me contacter</b-badge
                    >
                  </h6>
                  <h6>
                    <b-badge
                      href="#"
                      @click="goToCv()"
                      class="box__shadow bg-light text-blue"
                      ><i class="far fa-file-pdf"></i>&nbsp;CV PDF</b-badge
                    >
                  </h6>
                </div>
              </b-col>

              <b-col cols="8" class="pl-4 pr-4">
                <div class="text-right">
                  <SocialSharing
                    static
                    background="#00000000"
                    twitter="padcmoi"
                    gitlab="juliennaskot"
                    discord="257rUb9"
                    linkedin="julienjean-nice"
                    style="font-size: 1em"
                    class="text__shadow transition"
                  />
                </div>
                <div class="text-left mt-2">
                  <div class="font_bungee user-select-none text__shadow">
                    Mes compètences:
                    <h4 class="d-flex justify-content-around mt-2">
                      <div>
                        <i class="fab fa-html5"></i>
                      </div>
                      <div>
                        <i class="fab fa-css3-alt"></i>
                      </div>
                      <div>
                        <i class="fab fa-js"></i>
                      </div>
                      <div>
                        <i class="fab fa-bootstrap"></i>
                      </div>
                      <div>
                        <i class="fab fa-php"></i>
                      </div>
                      <div>
                        <i class="fab fa-node-js"></i>
                      </div>
                      <div>
                        <i class="fab fa-vuejs"></i>
                      </div>
                      <div>
                        <i class="fab fa-symfony"></i>
                      </div>
                    </h4>
                  </div>
                </div>
                <div class="text-left mt-4">
                  <div class="font_bungee user-select-none">
                    <WordCarouselFade
                      tag="span"
                      :words="view_home.wideScreenWordsCarouselExtends"
                      class="text__shadow"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </article>
        </div>
        <!-- FRONT -->

        <!-- BACK  -->
        <div class="container__card back box__shadow">
          <aside>
            <b-badge
              href="#"
              variant="light"
              class="px-1"
              @click="handleReverseCard"
            >
              <b-icon :icon="card_flipped ? 'front' : 'back'" />
            </b-badge>
            <b-badge
              href="#"
              :variant="isRunningDragRandom ? 'success' : 'danger'"
              class="px-1"
              @click="
                isRunningDragRandom ? dragRandomStart() : dragRandomStop()
              "
            >
              <b-icon :icon="isRunningDragRandom ? 'play' : 'stop'" />
            </b-badge>
            <b-badge
              variant="light"
              class="px-1"
              v-on:mouseover="setDrag"
              v-on:mouseleave="removeDrag"
            >
              <b-icon icon="arrows-move" />
            </b-badge>
            <b-badge
              href="#"
              variant="danger"
              class="px-1"
              @click="no_implemented"
            >
              <b-icon icon="x-square-fill" />
            </b-badge>
          </aside>
          <div class="text-center mt-3">
            <div class="mt-3 font_bungee text__shadow user-select-none">
              <WordCarouselFade
                tag="p"
                :words="view_home.wordsCarouselMain"
                class="text__shadow"
              />
              <p>
                Voici une perspective 3D CSS3 avec opacité, on ne voit pas le
                contenu caché par transparence.
              </p>
              <p>
                Vous pouvez déplacer cette fenêtre avec l’icône
                <b-badge
                  variant="light"
                  class="px-1"
                  v-on:mouseover="setDrag"
                  v-on:mouseleave="removeDrag"
                >
                  <b-icon icon="arrows-move" />
                </b-badge>
              </p>
              <p>
                Revenir au Verso avec l’icône
                <b-badge
                  href="#"
                  variant="light"
                  class="px-1"
                  @click="handleReverseCard"
                >
                  <b-icon :icon="card_flipped ? 'front' : 'back'" />
                </b-badge>
              </p>
            </div>
          </div>
        </div>
        <!-- BACK -->
      </div>
    </b-container>
    <div id="area-draggable"></div>
    <!--  -->
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DragWindow from './DragWindow'

export default {
  mixins: [DragWindow],
  components: {
    VueFlux: () => import('@/components/VueFlux'),
    SocialSharing: () => import('@/components/SocialSharing'),
    WordCarousel: () => import('@/components/WordCarousel'),
    WordCarouselFade: () => import('@/components/WordCarouselFade'),
    QrCode: () => import('@/components/QrCode'),
  },
  data() {
    return {
      card_flipped: false,
      show_qr_code: false,
      idCard: {
        x: 0,
        y: 0,
      },
      show_qrcode_timer: 0,
    }
  },
  computed: {
    ...mapState(['commons', 'events', 'view_home']),
    ...mapGetters(['view_home/identity']),
    vuefluxStyle() {
      return {
        height: this.events.height + 'px',
      }
    },
    containerStyle() {
      return {
        overflow: 'hidden',
        width: this.events.width + 'px',
        height: this.events.height + 'px',
        padding: 0,
        margin: 0,
        'margin-bottom': '-56px',
        '--identity': 'url(' + this['view_home/identity'] + ')',
        '--idcard-x': parseInt(this.idCard.x) + 'px',
        '--idcard-y': parseInt(this.idCard.y) + 'px',
      }
    },
    qrcodeTimer() {
      return parseInt(this.show_qrcode_timer)
    },
  },
  mounted() {
    this.handleIdCardPosition()
    this.initReverseCard()
    this.handleQrCode()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutstate_card_flipped1)
    clearTimeout(this.timeoutstate_card_flipped2)
    clearTimeout(this.timeoutstate_show_qrcode)
    clearInterval(this.intervalstate_show_qrcode)
  },
  methods: {
    handleQrCode() {
      this.show_qr_code = true
      this.show_qrcode_timer = 30
      clearTimeout(this.timeoutstate_show_qrcode)
      clearInterval(this.intervalstate_show_qrcode)
      this.intervalstate_show_qrcode = setInterval(() => {
        this.show_qrcode_timer--
      }, 1000)
      this.timeoutstate_show_qrcode = setTimeout(() => {
        this.show_qr_code = false
        clearInterval(this.intervalstate_show_qrcode)
      }, 30000)
    },
    handleIdCardPosition() {
      const position = localStorage.getItem(this.set['windowDrag'])
      if (typeof position === 'string') {
        this.idCard = {
          x: parseInt(JSON.parse(position).x) || 50,
          y: parseInt(JSON.parse(position).y) || 50,
        }
      } else {
        this.idCard = {
          // x: window.innerWidth - 550,
          x: 100,
          y: window.innerHeight - 356,
        }
      }
    },
    initReverseCard() {
      if (localStorage.getItem('initReverseCard')) return
      this.timeoutstate_card_flipped1 = setTimeout(() => {
        this.card_flipped = !this.card_flipped
      }, 2000)
      this.timeoutstate_card_flipped2 = setTimeout(() => {
        this.card_flipped = !this.card_flipped
      }, 19000)
    },
    handleReverseCard() {
      clearTimeout(this.timeoutstate_card_flipped1)
      clearTimeout(this.timeoutstate_card_flipped2)
      this.card_flipped = !this.card_flipped
      localStorage.setItem('initReverseCard', '1')
    },
    no_implemented() {
      alert('Pas encore implémenté')
    },
    goToCv() {
      window.location.href = 'https://api.naskot.fr/api.php/go/to/url?qr=53381a3cdccbfb8fe60b4149b20aef2e'
    },
  },
}
</script>

<style scoped>
:root {
  --identity: none;
  --idcard-x: 0;
  --idcard-y: 0;
}

/* xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */

.font_bungee {
  font-family: 'Bungee', cursive;
}
@keyframes slideCard {
  0% {
    opacity: 0;
    transform: translateX(-1000%);
  }
  85% {
    transform: translateX(5%);
  }
  90% {
    transform: translateX(10%);
  }
  95% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0%);
  }
}
.slideCard {
  animation-name: slideCard;
  animation-duration: 1.2s;
  /* transition: 0.5s; */ /* Pas de transition quand on une fenetre peut etre déplacée */
}
.container__scene {
  position: absolute;
  top: var(--idcard-y);
  left: var(--idcard-x);
  width: 550px;
  height: 250px;
  padding: 0;
  margin: 0;
  z-index: 999; /* pour empecher les images de vueflux de passer devant le container */
}
.container__perspective {
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 0.85s;
}
.container__perspective.is-flipped {
  transform: translateX(-100%) rotateY(-180deg);
}
.backface_visibility {
  backface-visibility: hidden;
}
.front {
  position: absolute;
  width: 550px;
  height: 250px;
  background: #163a58dd;
  transform: rotateY(0deg);
}
.back {
  position: absolute;
  width: 550px;
  height: 250px;
  background: #c02e14dd;
  transform: rotateY(180deg);
}
.container__card {
  border: 2px dotted white;
  border-radius: 15px;
  padding: 0 1em;
  backface-visibility: hidden;
}
.container__card aside {
  position: absolute;
  font-size: 1.5em;
  top: -17.5px;
  right: 25px;
  backface-visibility: hidden;
}
.container__card .__separator {
  margin-bottom: 10px;
  height: 200px;
  border: none;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  backface-visibility: hidden;
}
.text__shadow {
  text-shadow: 1px 1px 2px #333, 5px 5px 10px black;
}
.box__shadow {
  box-shadow: 1px 1px 2px #333, 5px 5px 10px black;
}
.container__card .identity_picture {
  margin-top: -10px;
  height: 130px;
  width: 130px;
  background-color: white;
  background: conic-gradient(
    white,
    #163a58dd,
    white,
    #163a58dd,
    white,
    #163a58dd,
    white,
    #163a58dd,
    white
      /* #ffc107,
    #163a58dd,
    #ffc107,
    #163a58dd,
    #ffc107,
    #163a58dd,
    #ffc107,
    #163a58dd,
    #ffc107 */
  );
  clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
}
.container__card .identity_picture::before {
  content: '';
  display: block;
  position: relative;
  top: 5px;
  left: 5px;
  height: 120px;
  width: 120px;
  background-image: var(--identity);
  border-radius: 50%;
  background-size: cover;
  background-position: 0% 75%;
  border: 0.5em solid transparent;
  clip-path: polygon(
    92.32051% 40%,
    93.79385% 43.1596%,
    94.69616% 46.52704%,
    95% 50%,
    94.69616% 53.47296%,
    93.79385% 56.8404%,
    92.32051% 60%,
    79.82051% 81.65064%,
    77.82089% 84.50639%,
    75.35575% 86.97152%,
    72.5% 88.97114%,
    69.3404% 90.44449%,
    65.97296% 91.34679%,
    62.5% 91.65064%,
    37.5% 91.65064%,
    34.02704% 91.34679%,
    30.6596% 90.44449%,
    27.5% 88.97114%,
    24.64425% 86.97152%,
    22.17911% 84.50639%,
    20.17949% 81.65064%,
    7.67949% 60%,
    6.20615% 56.8404%,
    5.30384% 53.47296%,
    5% 50%,
    5.30384% 46.52704%,
    6.20615% 43.1596%,
    7.67949% 40%,
    20.17949% 18.34936%,
    22.17911% 15.49361%,
    24.64425% 13.02848%,
    27.5% 11.02886%,
    30.6596% 9.55551%,
    34.02704% 8.65321%,
    37.5% 8.34936%,
    62.5% 8.34936%,
    65.97296% 8.65321%,
    69.3404% 9.55551%,
    72.5% 11.02886%,
    75.35575% 13.02848%,
    77.82089% 15.49361%,
    79.82051% 18.34936%
  );
}
.container__card .identity_picture:hover {
  transform: scale(1.2);
  z-index: 1000;
}

.fa-html5 {
  color: #e94e2f;
}
.fa-css3-alt {
  color: #0f76b9;
}
.fa-js {
  color: #dab92c;
}
.fa-bootstrap {
  color: #7a54b3;
}
.fa-php {
  color: #8a93bd;
}
.fa-node-js {
  color: #83cd29;
}
.fa-vuejs {
  color: #41b883;
}
.fa-symfony {
  color: #9a9a9a;
}
/* ---------------------------------- */
</style>
