export default {
  data() {
    return {
      set: {
        windowDrag: "id-card",
        areaDraggable: "area-draggable",
      },
      canDrag: false,
      FULLSCREEN_MARGINS: -10,
      MARGINS: 4,
      clicked: null,
      rightScreenEdge: null,
      bottomScreenEdge: null,
      preSnapped: null,
      b: null,
      x: null,
      y: null,
      e: null,
      redraw: false,
      stateAnimationFrame: null,
      dragRandom_state: null,
    };
  },
  computed: {
    isRunningDragRandom() {
      return this.dragRandom_state === null;
    },
    canMove() {
      return this.canDrag;
    },
    windowDrag() {
      return document.getElementById(this.set["windowDrag"] || "");
    },
    areaDraggable() {
      return document.getElementById(this.set["areaDraggable"] || "");
    },
  },
  mounted() {
    if (!this.windowDrag || !this.areaDraggable) return;

    // Mouse events
    this.windowDrag.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mousemove", this.onMove);
    document.addEventListener("mouseup", this.onUp);

    // Touch events
    this.windowDrag.addEventListener("touchstart", this.onTouchDown);
    document.addEventListener("touchmove", this.onTouchMove);
    document.addEventListener("touchend", this.onTouchEnd);

    this.animate();
  },
  beforeDestroy() {
    if (!this.windowDrag || !this.areaDraggable) return;
    this.dragRandomStop();

    // Mouse events
    this.windowDrag.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mousemove", this.onMove);
    document.removeEventListener("mouseup", this.onUp);

    // Touch events
    this.windowDrag.removeEventListener("touchstart", this.onTouchDown);
    document.removeEventListener("touchmove", this.onTouchMove);
    document.removeEventListener("touchend", this.onTouchEnd);

    cancelAnimationFrame(this.stateAnimationFrame);
  },
  methods: {
    // Random fun
    dragRandomStart() {
      clearTimeout(this.dragRandom_state);
      this.dragRandom_state = setTimeout(() => {
        this.alternatePositionX = !this.alternatePositionX;
        const x = this.alternatePositionX ? 0 : window.innerWidth - 500;
        const y = this.$tools.getRandomInt(parseInt(this.events.height - 306));

        if (this.windowDrag) {
          this.windowDrag.style["transition"] = "all 1.2s linear";
          // this.windowDrag.style["animation"] = "rebounce 1.2s linear 1";
          this.windowDrag.style.left = x + "px";
          this.windowDrag.style.top = y + "px";
        }

        this.dragRandomStart();
      }, 1200);
    },
    dragRandomStop() {
      if (this.windowDrag) {
        this.windowDrag.style.transition = "0s";
        this.windowDrag.style["animation"] = "none";
      }
      clearTimeout(this.dragRandom_state);
      this.dragRandom_state = null;
    },
    // Random fun
    setDrag() {
      this.canDrag = true;
    },
    removeDrag() {
      this.canDrag = false;
    },
    onTouchDown(e) {
      onDown(e.touches[0]);
      e.preventDefault();
    },
    onTouchMove(e) {
      onMove(e.touches[0]);
    },
    onTouchEnd(e) {
      if (e.touches.length == 0) this.onUp(e.changedTouches[0]);
    },
    onMouseDown(e) {
      this.onDown(e);
      e.preventDefault();
    },
    onDown(e) {
      this.calc(e);

      this.clicked = {
        x: this.x,
        y: this.y,
        isMoving: this.canMove,
      };
    },
    onMove(ee) {
      this.calc(ee);
      this.e = ee;
      this.redraw = true;
    },
    onUp(e) {
      this.calc(e);

      if (this.clicked && this.clicked.isMoving) {
        this.dragRandomStop();

        var snapped = {
          width: this.b.width,
          height: this.b.height,
        };

        if (
          this.b.top < this.FULLSCREEN_MARGINS ||
          this.b.left < this.FULLSCREEN_MARGINS ||
          this.b.right > window.innerWidth - this.FULLSCREEN_MARGINS ||
          this.b.bottom > window.innerHeight - this.FULLSCREEN_MARGINS
        ) {
          this.setBounds(this.windowDrag, 100, 100, true);
          this.preSnapped = snapped;
        } else if (this.b.top < this.MARGINS) {
          this.setBounds(this.windowDrag, 100, 100, true);
          this.preSnapped = snapped;
        } else if (this.b.left < this.MARGINS) {
          this.setBounds(this.windowDrag, 100, 100, true);
          this.preSnapped = snapped;
        } else if (this.b.right > this.rightScreenEdge) {
          this.setBounds(this.windowDrag, window.innerWidth / 2, 0, true);
          this.preSnapped = snapped;
        } else if (this.b.bottom > this.bottomScreenEdge) {
          this.setBounds(this.windowDrag, 0, window.innerHeight / 2, true);
          this.preSnapped = snapped;
        } else {
          this.preSnapped = null;
        }

        this.hintHide();
      }

      this.clicked = null;
    },
    setBounds(element, x, y, transition = false) {
      if (transition) {
        this.windowDrag.style.transition = "0.25s";
      } else {
        clearTimeout(this.timeoutState_setBounds);
        this.timeoutState_setBounds = setTimeout(() => {
          this.windowDrag.style.transition = "0s";
        }, 500);
      }

      if (this.preSnapped) {
        // x = window.innerWidth / 2;
        x = 100;
        y = window.innerHeight / 2;
        localStorage.setItem(
          this.set["windowDrag"],
          JSON.stringify({
            x: x,
            y: y,
          })
        );
      }

      element.style.left = x + "px";
      element.style.top = y + "px";
    },
    hintHide() {
      this.setBounds(this.areaDraggable, this.b.left, this.b.top);
      this.areaDraggable.style.opacity = 0;

      var b = this.areaDraggable.getBoundingClientRect();
      this.areaDraggable.style.top = b.top + b.height / 2;
      this.areaDraggable.style.left = b.left + b.width / 2;
    },
    calc(e) {
      this.b = this.windowDrag.getBoundingClientRect();
      this.x = e.clientX - this.b.left;
      this.y = e.clientY - this.b.top;

      this.rightScreenEdge = window.innerWidth - this.MARGINS;
      this.bottomScreenEdge = window.innerHeight - this.MARGINS;
    },
    animate() {
      this.stateAnimationFrame = requestAnimationFrame(this.animate);

      if (!this.redraw) return;

      this.redraw = false;

      if (this.clicked && this.clicked.isMoving) {
        this.dragRandomStop();
        if (
          this.b.top < this.FULLSCREEN_MARGINS ||
          this.b.left < this.FULLSCREEN_MARGINS ||
          this.b.right > window.innerWidth - this.FULLSCREEN_MARGINS ||
          this.b.bottom > window.innerHeight - this.FULLSCREEN_MARGINS
        ) {
          this.setBounds(this.areaDraggable, 0, 0);
          this.areaDraggable.style.opacity = 0.2;
        } else if (this.b.top < this.MARGINS) {
          this.setBounds(this.areaDraggable, 0, 0);
          this.areaDraggable.style.opacity = 0.2;
        } else if (this.b.left < this.MARGINS) {
          this.setBounds(this.areaDraggable, 0, 0);
          this.areaDraggable.style.opacity = 0.2;
        } else if (this.b.right > this.rightScreenEdge) {
          this.setBounds(this.areaDraggable, window.innerWidth / 2, 0);
          this.areaDraggable.style.opacity = 0.2;
        } else if (this.b.bottom > this.bottomScreenEdge) {
          this.setBounds(this.areaDraggable, 0, window.innerHeight / 2);
          this.areaDraggable.style.opacity = 0.2;
        } else {
          this.hintHide();
        }

        this.windowDrag.style.top = this.e.clientY - this.clicked.y + "px";
        this.windowDrag.style.left = this.e.clientX - this.clicked.x + "px";

        localStorage.setItem(
          this.set["windowDrag"],
          JSON.stringify({
            x: this.windowDrag.style.left,
            y: this.windowDrag.style.top,
          })
        );
        return;
      }

      if (this.canMove) {
        this.dragRandomStop();
        this.windowDrag.style.cursor = "move";
      } else {
        this.windowDrag.style.cursor = "default";
      }
    },
  },
};
